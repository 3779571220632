import defaultTheme from './defaultTheme';

const whitelabelTheme = {
  primaryGreen: '#49c99e',
  primaryRed: '#de284a',
};

export default class AppConsoleTheme {
  constructor() {
    this.theme = {};
    this.setDefaultTheme();
  }

  setTheme = (theme, useDefault) => {
    this.theme = theme;
    const root = document.querySelector(':root');
    if (theme?.primaryColor) {
      root.style.setProperty('--primarycolor', theme?.primaryColor);
      root.style.setProperty(
        '--primaryalt',
        useDefault ? defaultTheme?.primaryAlt : theme?.primaryColor
      );
    } else {
      this.theme.primaryColor = defaultTheme?.primaryColor;
      root.style.setProperty('--primarycolor', defaultTheme?.primaryColor);
      root.style.setProperty('--primaryalt', defaultTheme?.primaryAlt);
    }
    if (useDefault) {
      root.style.setProperty('--primarygreen', defaultTheme?.primaryGreen);
    } else {
      this.theme.primaryGreen = defaultTheme?.primaryGreen;
      root.style.setProperty('--primarygreen', whitelabelTheme.primaryGreen);
    }
    if (useDefault) {
      root.style.setProperty('--primaryred', defaultTheme?.primaryRed);
    } else {
      this.theme.primaryRed = defaultTheme?.primaryRed;
      root.style.setProperty('--primaryred', whitelabelTheme.primaryRed);
    }

    if (theme?.buttonText) {
      root.style.setProperty('--overtext', theme?.buttonText === 'light' ? '#ffffff' : '#303335');
    } else {
      this.theme.buttonText = defaultTheme?.buttonText;
      root.style.setProperty(
        '--overtext',
        defaultTheme?.buttonText === 'light' ? '#ffffff' : '#303335'
      );
    }

    const buttonText = theme?.buttonText || defaultTheme?.buttonText || 'light';
    const textColor = buttonText === 'light' ? '#ffffff' : '#303335';
    this.theme.buttonText = buttonText;
    root.style.setProperty('--overtext', textColor);

    root.style.setProperty('--defaultprimarycolor', defaultTheme?.primaryColor);
    root.style.setProperty('--primarytitle', defaultTheme?.primaryTitle);
    root.style.setProperty('--primarydark', defaultTheme?.primaryDark);
    root.style.setProperty('--primarydark2', defaultTheme?.primaryDark2);
    root.style.setProperty('--primarylight', defaultTheme?.primaryLight);
    root.style.setProperty('--buttontext', '#ffffff');

    root.style.setProperty('--adminprimarycolor', defaultTheme?.primaryColor);
    root.style.setProperty('--adminovertext', '#ffffff');
    root.style.setProperty('--pixelovertext', '#ffffff');
    root.style.setProperty('--selfserveovertext', '#ffffff');
    root.style.setProperty('--selfserveprimarycolor', defaultTheme?.primaryColor);
    root.style.setProperty('--body-background-image', defaultTheme['body-background-image']);
    root.style.setProperty('--body-background-color', defaultTheme['body-background-color']);
    root.style.setProperty(
      '--login-block-background-color',
      defaultTheme['login-block-background-color']
    );
  };

  setDefaultTheme = () => {
    this.setTheme(defaultTheme, true);
  };
}
