import { VIDEO_COMPLETION, CLICKS, INCREMENTAL_REACH } from '~/constant';

export function buildProcessedEvent(payload) {
  const result = {
    eventLabel: '',
    metric: '',
    category: '',
    subCategories: [],
    subEventLabels: [],
    numSubCategories: 0,
  };

  const selectedEvent = payload.find((f) => f.selected);
  if (selectedEvent) {
    result.eventLabel = selectedEvent.event || '';
    result.category = selectedEvent.category || '';
    if (Array.isArray(selectedEvent.children)) {
      result.subCategories =
        selectedEvent.children.filter((f) => f.selected).map((m) => m.category) || [];
      result.subEventLabels =
        selectedEvent.children.filter((f) => f.selected).map((m) => m.event) || [];
      result.allSubCategoriesSelected =
        selectedEvent.children.length === result.subCategories.length;
    }
    result.numSubCategories = selectedEvent.numSubCategories || 0;
    result.isRevenueEvent = result.eventLabel.toUpperCase() === 'REVENUE';
    result.metric = result.isRevenueEvent ? 'ROAS' : 'CPA';
    result.isVcrEvent = result.eventLabel === VIDEO_COMPLETION;
    if (result.isVcrEvent) {
      result.metric = 'VCR';
    }
    result.isCtrEvent = result.eventLabel === CLICKS;
    if (result.isCtrEvent) {
      result.metric = 'CTR';
    }
    result.isFalseEvent = result.isVcrEvent || result.isCtrEvent;
    result.isIncrementalReach = result.eventLabel === INCREMENTAL_REACH;
    if (result.isIncrementalReach) {
      result.metric = 'incrementalReachPercentage';
    }
  }
  return result;
}

export function handleError(error, errorMessage) {
  if (!errorMessage) {
    errorMessage = 'Request Failed. Try again or contact support.';
  }
  this.$emit('showErrorModal', errorMessage, error);
}
